import React, {useEffect, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import axios from "axios";
import {Button, message, Modal, Table} from "antd";
import md5 from 'md5';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';

function ComplainSearchSeeMore() {
    const [messageApi, contextHolder] = message.useMessage();
    const [searchParams] = useSearchParams();
    const outTradeNo = searchParams.get('outTradeNo');
    const processStatus = searchParams.get('processStatus');
    const type = searchParams.get('type');
    const isMerchantProcessing = type === 'app' ? processStatus === 'MERCHANT_PROCESSING' : processStatus === 'WAIT_PROCESS'

    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    let selectRowRef = useRef({})
    let selectedIndexRef = useRef(-1)
    const [isRefundLoading, setRefundLoading] = useState(false)

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        doRefund()
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const doRefund = async () => {
        setRefundLoading(true)
        const selectRow = selectRowRef.current
        const sign = md5(`${selectRow.payTradeNo}2023`)
        try {
            const response = await axios.post('https://www.bishicaijing.com/transfer/data/refund', {
                payTradeNo: selectRow.payTradeNo, sign, productName: selectRow.productName,
            });
            if (!response.data || !response.data.error) {
                messageApi.open({
                    type: 'error', content: '服务器异常，请重试',
                });
                return
            }
            if (response.data.error.returnCode !== 0) {
                messageApi.open({
                    type: 'error', content: response.data.error.returnUserMessage,
                });
                return
            }
            if (response.data.error.returnCode === 0) {
                messageApi.open({
                    type: 'success', content: '退款成功',
                });
                fetchData()
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            messageApi.open({
                type: 'error', content: "服务器异常，请重试，error=" + error.message,
            });
        } finally {
            setRefundLoading(false)
        }
    }

    const fetchData = async () => {
        try {
            const response = await axios.post('https://www.bishicaijing.com/transfer/data/queryAliBillListByOutTradeNo', {
                outTradeNo: outTradeNo
            });
            if (!response.data || !response.data.error) {
                messageApi.open({
                    type: 'error', content: '服务器异常，请重试',
                });
                return
            }
            if (response.data.error.returnCode !== 0) {
                messageApi.open({
                    type: 'error', content: response.data.error.returnUserMessage,
                });
                return
            }

            setData(response.data.data);
        } catch (error) {
            messageApi.open({
                type: 'error', content: error,
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, [outTradeNo]);

    const isDanger = (record) => {
        return record.complainCode !== 0
    }

    const columns = [{
        title: '产品', dataIndex: 'productName', key: 'productName',
    }, {
        title: '用户ID', dataIndex: 'aliBuyerID', key: 'aliBuyerID',
    }, {
        title: '商家订单号&第三方交易号',
        key: 'outTradeNo',
        render: (_, record) => (<div style={{display: 'flex', flexDirection: 'column'}}>
            <div> 商家订单号：{record.outTradeNo}</div>
            <div>第三方：{record.payTradeNo}</div>
        </div>)
    }, {
        title: '订单金额', dataIndex: 'amount', key: 'amount',
    }, {
        title: '创建时间', dataIndex: 'payTime', key: 'payTime',
    }, {
        title: '投诉状态', key: 'cycleBillStatus', render: (_, record) => {
            const danger = isDanger(record)
            const fontColor = danger ? 'red' : 'black'
            return (
                <div style={{color: fontColor}}>
                    <div>
                        {danger ? `用户状态：用户有投诉，谨慎退款！！(${rendComplainState(record)})` : '用户状态：用户无待处理投诉'}
                    </div>
                    <div>
                        是否投诉的是该笔订单：{record.isComplain === 1 ? '是' : '否'}
                    </div>
                </div>)
        }
    }, {
        title: '操作', key: 'action', render: (_, record, index) => (<div>
            {record.cycleBillStatus === 1 ? <Button type='primary'
                                                    loading={isRefundLoading && index === selectedIndexRef.current}
                                                    danger={isDanger(record)}
                                                    onClick={() => {
                                                        selectRowRef.current = record;
                                                        selectedIndexRef.current = index;
                                                        showModal()
                                                    }}>{'点击退款'}</Button> : <div>已完成退款</div>}
        </div>)
    }];

    const rendComplainState = (record) => {
        switch (record.complainCode) {
            case 0:
                return "无投诉";
            case 1:
                return "仅小程序投诉";
            case 2:
                return "仅交易投诉";
            case 3:
                return "小程序和交易投诉均投诉";
            default:
                return "未知状态";
        }
    }

    return <div style={{margin: 20}}>
        {contextHolder}
        <h1>订单号：{outTradeNo} {isMerchantProcessing ?
            <font color={'red'}>(该用户订单投诉未处理,请慎重点击退款！)</font> : ''}
        </h1>
        <Table columns={columns} dataSource={data} pagination={false}/>
        <Modal title="重要提示"
               okButtonProps={{
                   danger: isDanger(selectRowRef.current)
               }}
               cancelText='取消'
               okText='确认退款'
               open={isModalOpen}
               onOk={handleOk}
               onCancel={handleCancel}>
            <p style={{color: isDanger(selectRowRef.current) ? "red" : 'black'}}>{isDanger(selectRowRef.current) ? selectRowRef.current.complainTips : '确认要退款吗'}</p>
        </Modal>
    </div>;
}

export default ComplainSearchSeeMore;

