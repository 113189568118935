import {useRef, useState} from "react";
import React from 'react';
import {Card, Table, Button, Modal, message} from "antd";
import axios from "axios";
import Search from "antd/lib/input/Search";
import md5 from "md5";

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';

function ZfbOrderSearch() {
    const [messageApi, contextHolder] = message.useMessage();
    const [data, setData] = useState([])
    const [isSearchLoading, setSearchLoading] = useState(false)
    const [isRefundLoading, setRefundLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    let selectRowRef = useRef({})
    let selectRowIndexRef = useRef(-1)

    const columns = [{
        title: '用户ID', dataIndex: 'aliBuyerID', key: 'aliBuyerID',
    }, {
        title: '订单号',
        key: 'outTradeNo',
        render: (_, {outTradeNo, payTradeNo}) => (<div style={{display: 'flex', flexDirection: 'column'}}>
            <div>商家订单号：{outTradeNo}</div>
            <div>支付宝订单号：{payTradeNo}</div>
        </div>)
    }, {
        title: '支付时间', dataIndex: 'payTime', key: 'payTime',
    }, {
        title: '金额', dataIndex: 'amount', key: 'amount',
    }, {
        title: '产品名称', dataIndex: 'productName', key: 'productName',
    }, {
        title: '退款时间', dataIndex: 'refundTime', key: 'refundTime',
    }, {
        title: '签约状态', dataIndex: 'signStatusDesc', key: 'signStatusDesc',
    }, {
        title: '投诉状态', key: 'cycleBillStatus', render: (_, record) => {
            const danger = isDanger(record)
            const fontColor = danger ? 'red' : 'black'
            return (
                <div style={{color: fontColor}}>
                    <div>
                        {danger ? `用户状态：用户有投诉，谨慎退款！！(${rendComplainState(record)})` : '用户状态：用户无待处理投诉'}
                    </div>
                    <div>
                        是否投诉的是该笔订单：{record.isComplain === 1 ? '是' : '否'}
                    </div>
                </div>)
        }
    }, {
        title: '操作', key: 'action', render: (_, record, index) => (<div>
            {record.cycleBillStatus === 1 ? <Button type='primary'
                                                    danger={isDanger(record)}
                                                    loading={isRefundLoading && index === selectRowIndexRef.current}
                                                    onClick={() => {
                                                        selectRowRef.current = record;
                                                        selectRowIndexRef.current = index
                                                        setIsModalOpen(true)
                                                    }}>可退款</Button> : <div>已完成退款</div>}
        </div>)
    }]

    const rendComplainState = (record) => {
        switch (record.complainCode) {
            case 0:
                return "无投诉";
            case 1:
                return "小程序投诉";
            case 2:
                return "交易投诉";
            case 3:
                return "小程序&交易投诉";
            default:
                return "未知投诉";
        }
    }

    const isDanger = (record) => {
        return record.complainCode !== 0
    }

    const onSearchClick = (value) => {
        setData([])
        doSearch(value)
    }

    const doSearch = async (value) => {
        setSearchLoading(true)
        const url = value.length > 20 ? 'https://www.bishicaijing.com/transfer/data/queryAliCyclePayByPayTradeNo' : 'https://www.bishicaijing.com/transfer/data/queryAliCyclePayList';
        try {
            const response = await axios.post(url, {
                payTradeNo: value, aliBuyerID: value
            });
            const responseData = response.data;
            if (!responseData || !responseData.error || responseData.error.returnCode !== 0) {
                throw new Error(responseData?.error?.returnUserMessage || '服务器异常，请重试');
            }
            const newData = responseData.data instanceof Array ? responseData.data : [responseData.data];
            setData(newData);
        } catch (error) {
            console.error('Fetch data error:', error);
            setData([]);
            messageApi.open({
                type: 'error', content: '服务器异常，请重试' + error.message,
            });
        } finally {
            setSearchLoading(false);
        }
    }

    const handleOk = () => {
        setIsModalOpen(false)
        setRefundLoading(true)
        doRefund()
    };

    const handleCancel = () => {
        setIsModalOpen(false)
    };

    const doRefund = async () => {
        const sign = md5(`${selectRowRef.current.payTradeNo}2023`);
        try {
            const response = await axios.post('https://www.bishicaijing.com/transfer/data/refund', {
                payTradeNo: selectRowRef.current.payTradeNo, sign, productName: selectRowRef.current.productName,
            });
            if (!response.data || !response.data.error || response.data.error.returnCode !== 0) {
                throw new Error(response.data?.error?.returnUserMessage || '服务器异常，请重试');
            }
            const newData = [...data];
            newData[selectRowIndexRef.current].cycleBillStatus = 2;
            setData(newData);
            messageApi.open({type: 'success', content: '退款成功'});
        } catch (error) {
            console.error('Error fetching data:', error);
            messageApi.open({type: 'error', content: '服务器异常，请重试:' + error.message});
        } finally {
            setRefundLoading(false);
        }
    }


    return (<div style={{display: 'flex', margin: 20, flexDirection: 'column'}}>
        {contextHolder}
        <Card>
            <div style={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
            }}>
                <Search
                    loading={isSearchLoading}
                    placeholder="请输入订单号或用户ID"
                    onSearch={onSearchClick}
                    enterButton="查询"
                    size="large"/>
            </div>
        </Card>
        <Card style={{marginTop: 20}}>
            <Table pagination={false}
                   columns={columns}
                   dataSource={data}/>
        </Card>
        <Modal title="提示"
               open={isModalOpen}
               onOk={handleOk}
               okButtonProps={{
                   danger: isDanger(selectRowRef.current),
               }}
               onCancel={handleCancel}>
            <p style={{color: isDanger(selectRowRef.current) ? "red" : 'black'}}>{isDanger(selectRowRef.current) ? selectRowRef.current.complainTips : '确认要退款吗'}</p>
        </Modal>
    </div>);

}

export default ZfbOrderSearch;
