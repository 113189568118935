import './ComplainSearch.css';
import React, {useRef, useState} from 'react';
import {DatePicker, Select, Button, Card, Table, Space, message, Modal} from "antd";
import axios from "axios";
import {Link} from "react-router-dom";
import TextArea from "antd/es/input/TextArea";

const {RangePicker} = DatePicker;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';

function ComplainSearch() {
    const [messageApi, contextHolder] = message.useMessage();
    let startDateRef = useRef('');
    let endDateRef = useRef('');
    let merchantCodeRef = useRef('fl')
    let typeRef = useRef('app')
    let selectRecordRef = useRef({})
    let selectRecordIndexRef = useRef(-1)
    let paginationRef = useRef({
        current: 1, pageSize: 20, total: 0, showTotal: (total) => `Total ${total} items`, pageSizeOptions: ['10', '20']
    })
    const [totalPending, setTotalPending] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tradeComplainInfos, setTradeComplainInfos] = useState([]);
    const [remarkList, setRemarkList] = useState([]);
    const [openRemarkList, setOpenRemarkList] = useState(false);
    const [openAddRemark, setOpenAddRemark] = useState(false);
    const [remarkInputValue, setRemarkInputValue] = useState('');
    const [doAddRemarkLoading, setDoAddRemarkLoading] = useState(false);
    const [clickAddRemarkLoading, setClickAddRemarkLoading] = useState(false);
    const [complaintOptions, setComplaintOptions] = useState([]);
    const [complaintState, setComplaintState] = useState('')
    const [dataColumns, setDataColumns] = useState([]);
    const [isBasicModalOpen, setIsBasicModalOpen] = useState(false);
    const [basicModelMessage, setBasicModelMessage] = useState("无");

    const appComplaintOptions = [{
        value: '', label: '全部状态',
    }, {
        value: 'MERCHANT_PROCESSING', label: '待处理',
    }, {
        value: 'MERCHANT_FEEDBACKED', label: '已处理',
    }, {
        value: 'FINISHED', label: '投诉完结',
    }, {
        value: 'CANCELLED', label: '投诉关闭',
    }, {
        value: 'PLATFORM_PROCESSING', label: '客服处理中',
    }, {
        value: 'PLATFORM_FINISH', label: '客服处理完结',
    }, {
        value: 'CLOSED', label: '投诉关闭',
    }];

    const tradeComplaintOptions = [{
        value: '', label: '全部状态',
    }, {
        value: 'WAIT_PROCESS', label: '待处理',
    }, {
        value: 'PROCESSING', label: '处理中',
    }, {
        value: 'PROCESSED', label: '处理完成',
    }, {
        value: 'DROP_COMPLAIN', label: '用户撤诉',
    }, {
        value: 'DROP_OVERDUE_COMPLAIN', label: '超时后用户撤诉',
    }, {
        value: 'DROP_OVERDUE_PROCESSED', label: '超时处理完成用户撤诉',
    }, {
        value: 'DROP_PROCESSED', label: '处理完成用户撤诉',
    }, {
        value: 'OVERDUE', label: '超时未处理',
    }, {
        value: 'OVERDUE_PROCESSED', label: '超时处理完成',
    }, {
        value: 'PART_OVERDUE', label: '部分超时未处理',
    }];

    const appColumns = [{
        title: '创建时间', dataIndex: 'gmtCreate', key: 'gmtCreate',
    }, {
        title: '投诉内容',
        key: 'complainContent',
        render: (_, record) => (<div style={{display: 'flex', flexDirection: 'column'}}>
            <div>诉求：{record.leafCategoryName}</div>
            <div>原因：{record.complainReason}</div>
            <div>内容：{record.content}</div>
        </div>)
    }, {
        title: '订单号&投诉号',
        key: 'outTradeNo',
        render: (_, record) => (
            <div style={{display: 'flex', flexDirection: 'column', maxWidth: 300}}>
                <div>商家订单号：{record.merchantOrderNo}</div>
                <div>支付宝订单号：{record.tradeNo}</div>
                <div>投诉号：{record.complainEventId}</div>
            </div>)
    }, {
        title: '投诉人信息', key: 'userInfo',
        render: (_, record) => (
            <div style={{display: 'flex', flexDirection: 'column', maxWidth: 301}}>
                <div>支付宝ID：{record.aliBuyerID && record.aliBuyerID !== '' ? record.aliBuyerID : '无'}</div>
                <div onClick={() => {
                    searchPhoneNum(record.phoneNo, record.aliBuyerID)
                }}>
                    <font color={record.phoneNo == null || !record.phoneNo.includes('*') ? '#1F1F1F' : '#1777FF'}>
                        手机号：{record.phoneNo && record.phoneNo !== '' ? record.phoneNo : '无'}
                    </font>
                </div>
            </div>)
    }, {
        title: '订单状态', key: 'status', render: (_, record) => (<div style={{color: getAppStateTextColor(record)}}>
            {getAppStateText(record)}
        </div>)
    }, {
        title: '更新时间', dataIndex: 'gmtModified', key: 'gmtModified',
    }, {
        title: '完结时间', dataIndex: 'gmtFinished', key: 'gmtFinished',
    }, {
        title: '操作', key: 'action', render: (_, record, index) => (<Space size="middle">
            <Link
                to={`/complainSearchSeeMore?outTradeNo=${record.merchantOrderNo}&processStatus=${record.status}&type=${typeRef.current}`}
                target='_blank'>
                <Button type='primary'>查看全部</Button>
            </Link>
            <Button type={record.hasRemark === 1 ? 'primary' : 'default'}
                    loading={clickAddRemarkLoading && index === selectRecordIndexRef.current}
                    onClick={() => {
                        selectRecordRef.current = record;
                        selectRecordIndexRef.current = index
                        onRemarkClick()
                    }}>备注</Button>
        </Space>), width: 150
    }]

    const tradeColumns = [{
        title: '创建时间', dataIndex: 'gmtCreate', key: 'gmtCreate',
    }, {
        title: '投诉内容',
        key: 'complainContent',
        render: (_, record) => (<div style={{display: 'flex', flexDirection: 'column'}}>
            <div>{record.content}</div>
        </div>)
    }, {
        title: '订单号&投诉号',
        key: 'outTradeNo',
        render: (_, record) => (
            <div style={{display: 'flex', flexDirection: 'column', maxWidth: 300}}>
                <div>商家订单号：{record.merchantOrderNo}</div>
                <div>支付宝订单号：{record.tradeNo}</div>
                <div>投诉号：{record.complainEventId}</div>
            </div>)
    }, {
        title: '投诉人信息', key: 'phoneNo',
        render: (_, record) => (
            <div style={{display: 'flex', flexDirection: 'column', maxWidth: 300}}>
                <div>支付宝ID：{record.aliBuyerID && record.aliBuyerID !== '' ? record.aliBuyerID : '无'}</div>
                <div onClick={() => {
                    searchPhoneNum(record.phoneNo, record.aliBuyerID)
                }}>
                    <font color={record.phoneNo == null || !record.phoneNo.includes('*') ? '#1F1F1F' : '#1777FF'}>
                        手机号：{record.phoneNo && record.phoneNo !== '' ? record.phoneNo : '无'}
                    </font>
                </div>
            </div>)
    }, {
        title: '订单状态', key: 'status', render: (_, record) => (<div style={{color: getTradeStateTextColor(record)}}>
            {record.statusDescription}
        </div>)
    }, {
        title: '过期时间', dataIndex: 'gmtOverdue', key: 'gmtOverdue',
    }, {
        title: '处理时间', dataIndex: 'gmtProcess', key: 'gmtProcess',
    }, {
        title: '处理信息', dataIndex: 'processMessage',
        render: (_, record) => (<div style={{display: 'flex', flexDirection: 'column'}}>
            <div>{record.processMessage === null ? '无' : record.processMessage}</div>
        </div>)
    }, {
        title: '操作', key: 'action', render: (_, record, index) => (<Space size="middle">
            <Link to={`/complainSearchSeeMore?outTradeNo=${record.merchantOrderNo}&processStatus=${record.status}`}
                  target='_blank'>
                <Button type='primary'>查看全部</Button>
            </Link>
            <Button type={record.hasRemark === 1 ? 'primary' : 'default'}
                    loading={clickAddRemarkLoading && index === selectRecordIndexRef.current}
                    onClick={() => {
                        selectRecordRef.current = record;
                        selectRecordIndexRef.current = index
                        onRemarkClick()
                    }}>备注</Button>
        </Space>), width: 150
    }]

    const remarkColumns = [{
        title: '备注', dataIndex: 'remark', key: 'remark',
    }, {
        title: '创建时间', dataIndex: 'createDate', key: 'createDate',
    }]

    const searchPhoneNum = (phoneNum, aliBuyerID) => {
        if (phoneNum == null || !phoneNum.includes('*')) {
            return
        }
        axios.post('https://www.bishicaijing.com/transfer/data/queryPhoneNo', {
            aliBuyerID
        }).then((response) => {
            const responseData = response.data
            if (!responseData || !responseData.error) {
                messageApi.open({
                    type: 'error', content: '服务器异常，请重试',
                });
                return
            }

            if (responseData.error.returnCode !== 0) {
                messageApi.open({
                    type: 'error', content: responseData.error.returnUserMessage,
                });
                return
            }
            setBasicModelMessage("手机号：" + responseData.data.phoneNo)
            setIsBasicModalOpen(true)
        }).catch((error) => {
            messageApi.open({
                type: 'error', content: error,
            });
        });
    }

    const onRemarkClick = () => {
        setClickAddRemarkLoading(true)
        doRequestRemarkData(() => {
            setOpenRemarkList(true)
        })
    }

    const doRequestRemarkData = (successCallback) => {
        setRemarkList([])
        axios.post('https://www.bishicaijing.com/transfer/data/queryComplainRemark', {
            complainEventID: selectRecordRef.current.complainEventId
        }).then((response) => {
            const responseData = response.data
            if (!responseData || !responseData.error) {
                setRemarkList([])
                messageApi.open({
                    type: 'error', content: '服务器异常，请重试',
                });
                return
            }

            if (responseData.error.returnCode !== 0) {
                setRemarkList([])
                messageApi.open({
                    type: 'error', content: responseData.error.returnUserMessage,
                });
                return
            }
            setRemarkList(responseData.data.list)
            successCallback && successCallback()
        }).catch((error) => {
            setRemarkList([])
            messageApi.open({
                type: 'error', content: error,
            });
        }).finally(() => {
            setClickAddRemarkLoading(false)
        });
    }

    const getAppStateTextColor = (record) => {
        if (record.status === 'MERCHANT_PROCESSING') {
            return 'red'
        } else if (record.status === 'FINISHED') {
            return 'brown'
        } else {
            return 'black'
        }
    }

    const getTradeStateTextColor = (record) => {
        if (record.status === 'WAIT_PROCESS') {
            return 'red'
        } else if (record.status === 'PROCESSED') {
            return 'brown'
        } else {
            return 'black'
        }
    }

    const getAppStateText = (record) => {
        switch (record.status) {
            case 'MERCHANT_PROCESSING':
                return '待处理';
            case 'MERCHANT_FEEDBACKED':
                return '已处理';
            case 'FINISHED':
                return '投诉完结';
            case 'CANCELLED':
                return '投诉关闭';
            case 'PLATFORM_PROCESSING':
                return '客服处理中';
            case 'PLATFORM_FINISH':
                return '客服处理完结';
            case 'CLOSED':
                return '投诉关闭';
        }
    }

    const onRangeChange = (dates, dateStrings) => {
        console.log(dates, dateStrings);
        if (dates) {
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            startDateRef.current = dateStrings[0];
            endDateRef.current = dateStrings[1];
        } else {
            startDateRef.current = '';
            endDateRef.current = '';
        }
    }

    const onMerchantChange = (value) => {
        merchantCodeRef.current = value
    }

    const onComplaintSelectChange = (value) => {
        setComplaintState(value)
    }

    const onTypeChange = (value) => {
        setComplaintState('')
        setComplaintOptions([])
        setTradeComplainInfos([])
        typeRef.current = value
        if (value === 'app') {
            setComplaintOptions(appComplaintOptions)
            setDataColumns(appColumns)
        } else {
            setComplaintOptions(tradeComplaintOptions)
            setDataColumns(tradeColumns)
        }
    }

    const onSearchClick = () => {
        doSearch(paginationRef.current)
    }

    const doSearch = (pagination) => {
        setTradeComplainInfos([])
        setTotalPending(0)
        setLoading(true)
        const url = typeRef.current === 'app' ? 'https://www.bishicaijing.com/transfer/data/queryComplainInfoList' : 'https://www.bishicaijing.com/transfer/data/queryTradeComplainInfoList';
        axios.post(url, {
            pageNum: pagination.current || 1,
            pageSize: pagination.pageSize || 20,
            startTime: startDateRef.current,
            endTime: endDateRef.current,
            merchantCode: merchantCodeRef.current,
            status: complaintState
        }).then((response) => {
            const responseData = response.data
            if (!responseData || !responseData.error) {
                setTradeComplainInfos([])
                messageApi.open({
                    type: 'error', content: '服务器异常，请重试',
                });
                return
            }

            if (responseData.error.returnCode !== 0) {
                setTradeComplainInfos([])
                messageApi.open({
                    type: 'error', content: responseData.error.returnUserMessage,
                });
                return
            }

            setTradeComplainInfos(responseData.data.tradeComplainInfos)
            setTotalPending(responseData.data.totalPending)
            paginationRef.current = {
                current: responseData.data.pageNum,
                pageSize: responseData.data.pageSize,
                total: responseData.data.totalNum,
                pageSizeOptions: ['10', '20'],
                showTotal: (total) => `共计 ${total} 条数据`,
            }
        }).catch((error) => {
            setTradeComplainInfos([])
            messageApi.open({
                type: 'error', content: error,
            });
        }).finally(() => {
            setLoading(false)
        });
    }

    const onTableChange = (pagination, filters, sorter) => {
        doSearch(pagination);
    }

    const onAddRemarkClick = () => {
        setOpenAddRemark(true)
    }

    const doAddRemark = () => {
        if (remarkInputValue == null || remarkInputValue.trim().length === 0) {
            messageApi.open({
                type: 'error', content: '请输入备注内容',
            });
            return
        }
        setDoAddRemarkLoading(true)
        axios.post('https://www.bishicaijing.com/transfer/data/addComplainRemark', {
            complainEventID: selectRecordRef.current.complainEventId,
            remark: remarkInputValue
        }).then((response) => {
            const responseData = response.data
            if (!responseData || !responseData.error) {
                setDoAddRemarkLoading(false)
                messageApi.open({
                    type: 'error', content: '服务器异常，请重试',
                });
                return
            }

            if (responseData.error.returnCode !== 0) {
                setDoAddRemarkLoading(false)
                messageApi.open({
                    type: 'error', content: responseData.error.returnUserMessage,
                });
                return
            }
            setRemarkList([])
            setRemarkInputValue('')
            selectRecordRef.current.hasRemark = 1
            doRequestRemarkData(() => {
                setDoAddRemarkLoading(false)
                setOpenAddRemark(false)
            })
        }).catch((error) => {
            setDoAddRemarkLoading(false)
            messageApi.open({
                type: 'error', content: error,
            });
        });
    }

    const totalPendingColor = totalPending === 0 ? 'black' : 'red';

    function handleBasicOk() {
        setIsBasicModalOpen(false)
    }

    return (<div style={{display: 'flex', margin: 20, flexDirection: 'column'}}>
        {contextHolder}
        <Card>
            <div style={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
            }}>
                <text>日期：</text>
                <RangePicker showTime onChange={onRangeChange}/>
                <text style={{marginLeft: 50}}>商户：</text>
                <Select
                    defaultValue='fl'
                    style={{width: 150}}
                    placeholder="请选择商户"
                    onChange={onMerchantChange}
                    options={[{
                        value: 'fl', label: '凤灵',
                    }, {
                        value: 'szat', label: '深圳',
                    }, {
                        value: 'jf', label: '姬发',
                    }, {
                        value: 'rsy', label: '融盛云',
                    }, {
                        value: 'juzi', label: '橘子科技',
                    },]}
                />
                <text style={{marginLeft: 50}}>投诉状态：</text>
                <Select
                    style={{width: 150}}
                    placeholder="请选择投诉状态"
                    value={complaintState}
                    onChange={onComplaintSelectChange}
                    options={complaintOptions && complaintOptions.length > 0 ? complaintOptions : appComplaintOptions}
                />
                <text style={{marginLeft: 50}}>投诉类型：</text>
                <Select
                    defaultValue='app'
                    style={{width: 150}}
                    placeholder="请选择投诉类型"
                    onChange={onTypeChange}
                    options={[{
                        value: 'app', label: '小程序投诉',
                    }, {
                        value: 'trade', label: '交易投诉',
                    }]}
                />
                <Button type="primary"
                        loading={loading}
                        onClick={onSearchClick}
                        style={{marginLeft: "auto"}}>搜索</Button>
            </div>
        </Card>
        <Card style={{marginTop: 20}}>
            <div style={{float: 'right', marginRight: 20}}>
                待处理<font style={{color: totalPendingColor}}>{totalPending}</font>个
            </div>
            <Table pagination={paginationRef.current}
                   columns={dataColumns && dataColumns.length > 0 ? dataColumns : appColumns}
                   onChange={onTableChange}
                   dataSource={tradeComplainInfos}/>
        </Card>
        <Modal
            maskClosable={false}
            centered
            open={openRemarkList}
            onCancel={() => setOpenRemarkList(false)}
            footer={null}
            width={1000}>
            <div>
                <font
                    style={{fontSize: 12}}>{`（订单号：${selectRecordRef.current.tradeNo}，投诉ID：${selectRecordRef.current.complainEventId}）`}
                </font>
                <Button type='primary' onClick={() => {
                    onAddRemarkClick()
                }}>新增备注</Button>
            </div>
            <Table columns={remarkColumns}
                   dataSource={remarkList}/>
        </Modal>
        <Modal
            maskClosable={false}
            title={'添加备注'}
            centered
            open={openAddRemark}
            cancelText={'取消'}
            okText={'确认'}
            onCancel={() => {
                setRemarkInputValue('')
                setOpenAddRemark(false)
            }}
            onOk={() => {
                doAddRemark()
            }}
            okButtonProps={{
                loading: doAddRemarkLoading
            }}
            width={800}>
            <div>
                <TextArea
                    value={remarkInputValue}
                    showCount
                    maxLength={2048}
                    placeholder="请输入备注信息"
                    onChange={e => setRemarkInputValue(e.target.value)}
                    style={{height: 120, resize: 'none', marginTop: 40, marginBottom: 50}}
                />
            </div>
        </Modal>
        <Modal closable={false}
               title="提示信息"
               open={isBasicModalOpen}
               footer={[
                   <Button key="ok" type="primary" onClick={handleBasicOk}>
                       确认
                   </Button>
               ]}>
            <p>{basicModelMessage}</p>
        </Modal>
    </div>);

}

export default ComplainSearch;
