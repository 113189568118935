import {useRef, useState} from "react";
import React from 'react';
import {Card, Table, Button, Modal, message} from "antd";
import axios from "axios";
import Search from "antd/lib/input/Search";
import md5 from "md5";

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';

function HwOrderSearch() {
    const [messageApi, contextHolder] = message.useMessage();
    const [data, setData] = useState([])
    const [phoneNumber,setPhoneNumber] = useState('')
    const [isSearchLoading, setSearchLoading] = useState(false)
    const [isRefundLoading, setRefundLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    let selectRowRef = useRef({})
    let selectRowIndexRef = useRef(-1)
    let clickButtonState = useRef(1)//点击按钮的状态，1是解约 2是退款并解约
    let inputValue = useRef("")

    const columns = [{
        title: '用户ID', key: 'userID', render: (_, record) => (
            <div style={{width: '100px'}}>{record.userID}</div>
        )
    }, {
        title: '订单信息',
        key: 'outTradeNo',
        render: (_, {outTradeNo, payTradeNo}) => (
            <div style={{display: 'flex', flexDirection: 'column', width: '150px'}}>
                <div>商家订单号：{outTradeNo}</div>
                <div>支付订单号：{payTradeNo}</div>
            </div>
        )
    }, {
        title: '手机号',
        key: 'phoneNumber',
        render: (_, {hasPhoneNumber,userID}) => (
            <div style={{display: 'flex', flexDirection: 'column', width: '150px'}}>
                {renderPhoneNumber(hasPhoneNumber,userID)}
            </div>
        )
    }, {
        title: '支付时间', dataIndex: 'payTime', key: 'payTime',
    }, {
        title: '金额', dataIndex: 'amount', key: 'amount',
    }, {
        title: '产品名称', dataIndex: 'productName', key: 'productName',
    }, {
        title: '退款时间', dataIndex: 'refundTime', key: 'refundTime',
    }, {
        title: '订单状态',
        key: 'signStatusDesc',
        render: (_, record) => (
            <div>
                <div>签约状态:{record.signStatusDesc}</div>
                <div>退款状态:{record.cycleBillStatus === 2 ? '已退款' : '未退款'}</div>
            </div>
        )
    }, {
        title: '操作', key: 'action', render: (_, record, index) => (renderRefundButton(_, record, index))
    }]

    const renderRefundButton = (_, record, index) => {

        if (index === data.length - 1) {
            return (
                <div>
                    <Button type='primary'
                            style={{marginLeft: '5px'}}
                            disabled={record.signStatus === 3}
                            loading={isRefundLoading && index === selectRowIndexRef.current}
                            onClick={() => {
                                clickButtonState.current = 1;
                                selectRowRef.current = record;
                                selectRowIndexRef.current = index
                                setIsModalOpen(true)
                            }}>{record.signStatus === 3 ? '已解约' : '解约'}</Button>
                    <Button type='primary' style={{marginLeft: '5px'}}
                            loading={isRefundLoading && index === selectRowIndexRef.current}
                            onClick={() => {
                                clickButtonState.current = 2;
                                selectRowRef.current = record;
                                selectRowIndexRef.current = index
                                setIsModalOpen(true)
                            }}>退款并解约</Button>
                </div>
            )
        }
    }

    const renderPhoneNumber = (hasPhoneNumber,userID) => {
        if (hasPhoneNumber) {
            if (phoneNumber) {
                return <div>{phoneNumber}</div>;
            } else {
                return <Button type='primary' onClick={() => {
                    doSearchPhoneNumber(userID)
                }}>点击查询手机号</Button>;
            }
        }
        return <div>未查询到手机号</div>;
    }

    const doSearchPhoneNumber = async (userID) => {
        const url = 'https://www.bishicaijing.com/transfer/data/getPhoneNumber';
        const sign = md5(`${userID}2024`);
        try {
            const response = await axios.post(url, {
                userID,
                sign
            });
            const responseData = response.data;
            if (!responseData || !responseData.error || responseData.error.returnCode !== 0) {
                throw new Error(responseData?.error?.returnUserMessage || '服务器异常，请重试');
            }
            setPhoneNumber(response.data.data.phoneNumber)
        } catch (error) {
            messageApi.open({
                type: 'error', content: '服务器异常，请重试' + error.message,
            });
        }
    }

    const onSearchClick = (value) => {
        inputValue.current = value
        doSearch()
    }

    const doSearch = async () => {
        setData([])
        setSearchLoading(true)
        const url = 'https://www.bishicaijing.com/transfer/data/queryHuaweiCyclePayList';
        let payTradeNo, phoneNumber;
        if (inputValue.current.length < 12) {
            payTradeNo = '';
            phoneNumber = inputValue.current;
        } else {
            payTradeNo = inputValue.current;
            phoneNumber = '';
        }
        try {
            const response = await axios.post(url, {
                payTradeNo,
                phoneNumber
            });
            const responseData = response.data;
            if (!responseData || !responseData.error || responseData.error.returnCode !== 0) {
                throw new Error(responseData?.error?.returnUserMessage || '服务器异常，请重试');
            }
            const newData = responseData.data instanceof Array ? responseData.data : [responseData.data];
            setPhoneNumber('')
            setData(newData);
        } catch (error) {
            setData([]);
            messageApi.open({
                type: 'error', content: '服务器异常，请重试' + error.message,
            });
        } finally {
            setSearchLoading(false);
        }
    }

    const handleOk = () => {
        setIsModalOpen(false)
        setRefundLoading(true)
        if (clickButtonState.current === 1) {
            doUnSign()
        } else {
            doRefund()
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false)
    };

    const doUnSign = async () => {
        try {
            const response = await axios.post('https://www.bishicaijing.com/transfer/data/huaweiUnsign', {
                userID: selectRowRef.current.userID,
            });
            if (!response.data || !response.data.error || response.data.error.returnCode !== 0) {
                throw new Error(response.data?.error?.returnUserMessage || '服务器异常，请重试');
            }
            messageApi.open({type: 'success', content: '已解约'});
            await doSearch()
        } catch (error) {
            messageApi.open({type: 'error', content: '服务器异常，请重试:' + error.message});
        } finally {
            setRefundLoading(false);
        }
    }

    //解约并退款
    const doRefund = async () => {
        const userID = selectRowRef.current.userID;
        const sign = md5(`${userID}2024`);
        try {
            const response = await axios.post('https://www.bishicaijing.com/transfer/data/huaweiRefund', {
                userID,
                sign
            });
            if (!response.data || !response.data.error || response.data.error.returnCode !== 0) {
                throw new Error(response.data?.error?.returnUserMessage || '服务器异常，请重试');
            }
            messageApi.open({type: 'success', content: '已解约并退款'});
            await doSearch()
        } catch (error) {
            console.error('Error fetching data:', error);
            messageApi.open({type: 'error', content: '服务器异常，请重试:' + error.message});
        } finally {
            setRefundLoading(false);
        }
    }


    return <div style={{display: 'flex', margin: 20, flexDirection: 'column'}}>
        {contextHolder}
        <Card>
            <div style={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
            }}>
                <Search
                    loading={isSearchLoading}
                    placeholder="请输入商户订单号或手机号"
                    onSearch={onSearchClick}
                    enterButton="查询"
                    size="large"/>
            </div>
        </Card>
        <Card style={{marginTop: 20}}>
            <Table pagination={false}
                   columns={columns}
                   dataSource={data}/>
        </Card>
        <Modal title="提示"
               open={isModalOpen}
               onOk={handleOk}
               onCancel={handleCancel}>
            <p>{clickButtonState.current === 1 ? '确认要退款吗?' : '将会退最后一笔订单并解约，确认要退款并解约吗?,'}</p>
        </Modal>
    </div>;

}

export default HwOrderSearch;
