// AppRouter.js
import React from 'react';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import App from "./app/App";
import ComplainSearch from './complainSearch/ComplainSearch';
import ZfbOrderSearch from "./orderSearch_zfb/ZfbOrderSearch";
import ComplainSearchSeeMore from "./complainSearchSeeMore/ComplainSearchSeeMore";
import HwOrderSearch from "./orderSearch_hw/HwOrderSearch";

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App/>} exact/>
                <Route path="/complainSearch" element={<ComplainSearch/>}/>
                <Route path="/complainSearchSeeMore" element={<ComplainSearchSeeMore/>}/>
                <Route path="/orderSearch" element={<ZfbOrderSearch/>}/>
                <Route path="/orderSearch_hw" element={<HwOrderSearch/>}/>
            </Routes>
        </Router>
    );
};

export default AppRouter;
