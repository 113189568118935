import React from 'react';
import {Link} from "react-router-dom";
import {Card, Image} from "antd";
import huawei from './logo_huawei.png'
import zhiFuBao from './logo_zhifubao.png'


function App() {

    return (
        <div style={{
            margin: 20,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-between',
        }}>
            <h1>用户管理系统</h1>
            <table>
                <tbody>
                <tr>
                    <td>
                        <Card>
                            <Image src={zhiFuBao} style={{width: '25px', marginRight: '5px'}}/>
                            <Link to={`/complainSearch`} target='_blank'>支付宝投诉处理平台</Link>
                        </Card>
                    </td>
                    <td>
                        <Card style={{marginLeft: 20}}>
                            <Image src={zhiFuBao} style={{width: '25px', marginRight: '5px'}}/>
                            <Link to={`/orderSearch`} target='_blank'>支付宝用户订单查询</Link>
                        </Card>
                    </td>
                </tr>
                </tbody>
                <tbody>
                <tr>
                    <td>
                        <div style={{marginTop: '10px'}}>
                            <Card>
                                <Image src={huawei} style={{width: '25px', marginRight: '5px'}}/>
                                <Link to={`/orderSearch_hw`} target='_blank'>华为投诉处理平台</Link>
                            </Card>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

export default App;

